@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

table {
	th {
		span.glyphicon {
			font-size:12px;
			margin-top:3px;
		}
	}
}

.lead-information {
	small {
		font-size: 12px;
		color: #777;
		white-space: nowrap;
	}
}

.lead-stage {
	margin-bottom:20px;
}

h1 {
	.label {
		font-size: 14px;
		vertical-align: middle;
	}
}